import React from "react";
import useDesc from "../hooks/useDesc";
import useKeywords from "../hooks/useKeywords";
import useTitle from "../hooks/useTitle";

function About() {
  useTitle("About the Project");
  useKeywords("About");
  useDesc(
    "About TomatenBot. TomatenBot once started as a small project for me andy my friends."
  );
  return (
    <div className="content">
      <div className="flex-column full-width">
        <h1>About the Project</h1>
        <h2>The Beginning</h2>
        <p>
          TomatenBot was a small project I worked on intended to be for me and
          my friends that I used as a JavaScript learning project. It is a hobby
          project that I plan to work on a little from time to time.
        </p>
        <h2>Discord.js V13 release</h2>
        <p>
          The bot was completely redone from the ground up, all the commands had
          been recoded and I really had fun trying out new stuff while doing so.
          <br />
          <br />
          While redoing the bot, I tried adding some more compilcated features.
          While this resulted in the messiest codebase I've ever seen myself, it
          mostly works ig.
          <br />
          <br />
          While I was redoing the bot anyway, I decided I don't want to just
          redo but to expand the features. The bot is btw still on V13 as I
          never took the time to rewrite again.
        </p>
        <h2>What it is now</h2>
        <p>
          Well, currently the bot, and also everything else, like the website,
          need way more time and dedication to get to a point I would accept the
          bot being kind of "done". I'm learning so much because of that project
          and I don't plan to end it (for now).
          <br />
          <i>~Luxor aka Lucjan Lubomski</i>
        </p>
      </div>
    </div>
  );
}

export default About;
